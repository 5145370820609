<template>
  <SearchLayout
    class="project-list"
    :class="{ pending }"
    :filters="filters"
    :pagination="pagination"
    no-items-text="Мы не нашли подходящие проекты"
    create-text="Разместить проект"
    :create-to="{ name: 'project-create' }"
  >
    <template v-if="!user?.is_specialist" #before>
      <BaseBanner>
        <template #title>Найди проект по <br>душе и заработай!</template>
        <template #steps>
          <BaseBannerStep number="1">
            <template #icon><img src="~/assets/img/icons/3d-form.png" alt="Ментор"></template>
            Заполняй анкету<br>в профиле
          </BaseBannerStep>
          <BaseBannerStep number="2">
            <template #icon><img src="~/assets/img/icons/3d-suitcase.png" alt="Календарь"></template>
            Откликайся<br>на проекты
          </BaseBannerStep>
          <BaseBannerStep number="3">
            <template #icon><img src="~/assets/img/icons/3d-chart.png" alt="Ментор"></template>
            Получай опыт и расти<br>как специалист
          </BaseBannerStep>
        </template>
        <template #actions>
          <BaseButton big :to="{ name: 'project-create' }">Разместить проект</BaseButton>
        </template>
      </BaseBanner>
    </template>

    <template #title>
      <PageTitle :note="pagination.total">Проекты</PageTitle>
    </template>
    <template #controls="{ isMobile }">
      <SortControl :chip="isMobile" v-if="sortOptions" :options="sortOptions" v-model="filter.sort" />
    </template>
    <template #skills="{ single, active }">
      <FilterSkills
        :items="meta.skills"
        :fetched="fetchedSkills"
        v-model="filter.skills"
        :single="single"
        :active="active.value"
      />
    </template>
    <template #payment="{ single, active }">
      <FilterPayment
        ref="slider"
        v-model:from="filter.payment_from"
        v-model:to="filter.payment_to"
        :min="meta.payment_min"
        :max="meta.payment_max"
        v-model:type="filter.payment_type"
        @change="onChange"
        :single="single"
        :active="active.value"
      />
    </template>
    <template #skillLevels="{ single, active }">
      <FilterSkillLevels
        :items="meta.skill_levels"
        v-model="filter.skillLevels"
        :single="single"
        :active="active.value"
      />
    </template>
    <template #busyness="{ single, active }">
      <FilterBusyness
        v-model="filter.busyness"
        :single="single"
        :active="active.value"
      />
    </template>

    <template #default>
      <ProjectSearchResultCard
        v-for="project in projects"
        :key="project.id"
        :project="project"
      />
    </template>

    <template #after>
      <BecomeMentor class="become-section" />
    </template>
  </SearchLayout>
</template>

<script setup>
import { FilterBuilder } from '~/utils/filters'
import usePagination from '~/composables/usePagination'
import { getProjectsMeta, searchProjects } from '~/api/projects'
import { useBecomeSpecialistRoute } from '~/composables/router'
import useIsMounted from '~/composables/useIsMounted'

const router = useRouter()
const route = useRoute()

const user = inject('user')
const becomeSpecialistRoute = useBecomeSpecialistRoute()

useHead({
  title: 'Удаленная проектная работа для фрилансеров - размести свой проект на Mentoring.digital'
})
useServerSeoMeta({
  description: 'Каталог проектов для фрилансеров. Удаленная работа для специалистов IT-направлений. Поиск работы в сервисе Mentoring.digital',
})

const sortOptions = [
  {
    id: 'new_first',
    name: 'Новые'
  },
  {
    id: 'price_desc',
    name: 'Ставка выше'
  },
  {
    id: 'price_asc',
    name: 'Ставка ниже'
  }
]

const filter = ref({
  skills: [],
  payment_type: null,
  skillLevels: [],
  busyness: null,
  sort: sortOptions[0].id
})

const { data: meta } = await useAsyncData(getProjectsMeta)
// filter.value.payment_from = meta.value.payment_min
// filter.value.payment_to = meta.value.payment_max

;(query => {
  if (query['skills[]']) filter.value.skills = [].concat(query['skills[]']).map(s => +s).filter(s => !isNaN(s))
  if (query.payment_from) filter.value.payment_from = +query.payment_from
  if (query.payment_to) filter.value.payment_to = +query.payment_to
  if (query.payment_type) filter.value.payment_type = query.payment_type
  if (query['skill_levels[]']) filter.value.skillLevels = [].concat(query['skill_levels[]']).map(s => +s).filter(s => !isNaN(s))
  if (query.busyness) filter.value.busyness = query.busyness
  if (query.sort) filter.value.sort = query.sort
})(route.query)

const slider = ref()
const onChange = (name) => {
  if (!filter.value[name]) return

  filter.value[name] = Math.max(
    name === 'payment_from' ? meta.value.payment_min : filter.value.payment_from, // min
    Math.min(
      name === 'payment_from' ? filter.value.payment_to : meta.value.payment_max, // max
      filter.value[name] // value
    )
  )

  nextTick(() => slider.value?.updateValue())
}

const filters = new FilterBuilder()
  .skills(filter, () => meta.value.skills)
  .payment(filter, meta, onChange)
  .skillLevels(filter, () => meta.value.skill_levels)
  .busyness(filter)
  .build()

const fetchedSkills = ref(filter.value.skills.slice())
const isMounted = useIsMounted()

const {
  loading: pending,
  pagination,
  page,
  items: projects,
  load
} = await usePagination(
  (page, perPage) => {
    const data = {
      page,
      'skills[]': filter.value.skills,
      'skill_levels[]': filter.value.skillLevels,
      ...Object.assign({}, filter.value, Object.fromEntries(['skills', 'skillLevels'].map(k => [k, undefined])))
    }
    if (isMounted.value
      || route.query.page
      || filters.some(f => !f.isClear?.value)
      || data.sort !== sortOptions[0].id)
      if (isMounted.value) router.replace({ query: data })

    fetchedSkills.value = filter.value.skills.slice()
    return searchProjects({
      ...data,
      per_page: perPage
    })
  },
  ref(5),
  { key: 'projects' }
)

watchDebounced(filter, () => {
  if (pagination.value.current_page === 1) load()
  else page.value = 1
}, { deep: true, debounce: 800 })
</script>

<style scoped lang="scss">
.project-list {
  &__controls {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
}
</style>
