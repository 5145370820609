<template>
  <FilterControl :name="!single ? 'Оплата' : null">
    <FilterRange
      ref="range"
      v-bind="props"
      v-model:from="modelFrom"
      v-model:to="modelTo"
      @change="emit('change', $event)"
      @submit="emit('submit')"
    />
    <ChipList
      class="filter-payment__type"
      select
      :items="paymentTypes"
      v-model="modelType"
      small
      grow
    />
  </FilterControl>
</template>

<script setup>
import { paymentTypes } from '~/data/project'

const props = defineProps(['from', 'to', 'min', 'max', 'type', 'single'])
const emit = defineEmits(['update:from', 'update:to', 'update:type', 'change', 'submit'])

const modelFrom = computed({
  get: () => props.from,
  set: value => emit('update:from', value)
})
const modelTo = computed({
  get: () => props.to,
  set: value => emit('update:to', value)
})
const modelType = computed({
  get: () => props.type,
  set: value => emit('update:type', value)
})

const range = ref()
defineExpose({ updateValue: () => range.value?.sliderUpdate() })
</script>

<style scoped lang="scss">
.filter-payment__type {
  margin-top: 24px;
}
</style>
